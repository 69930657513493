<template>

<!--  <el-affix class="hidden-sm-and-down">-->

    <div class="base-container hidden-sm-and-down">

      <div style="height: 64px;width: 100%;">

        <div class="top-container hidden-sm-and-down">
          <div class="header-container">

            <img src="../../assets/index/logo.png" alt="logo" style="width: 90px;height: 36px;margin-left: 24px;" />

            <div class="menu-container" :style="!isHome? 'margin-left:30px;':''">

              <el-tabs v-model="localSelectedTab" class="custom-tabs no-animation" @tab-click="handleClick">
                <el-tab-pane label="首页" name="index"></el-tab-pane>
                <el-tab-pane label="文献典籍" name="library"></el-tab-pane>
                <el-tab-pane label="精选专栏" name="selected"></el-tab-pane>
                <el-tab-pane label="九典名篇" name="famous"></el-tab-pane>
                <el-tab-pane label="关于我们" name="about"></el-tab-pane>
                <el-tab-pane label="我的书架" name="mine"></el-tab-pane>
                <el-tab-pane label="下载APP" name="download"></el-tab-pane>
              </el-tabs>
            </div>

            <div class="sousuo" v-show="!isHome">
              <el-input type="text" v-model="keyWord" class="inputK" @keydown.enter.prevent="handleEnterKeyPress"  placeholder="请输入典籍,专栏或文章的名称"></el-input>
              <span style="line-height: 28px;color:#BEA488">|</span>
              <img @click="handleEnterKeyPress" src="../../assets/others/icon_search.png" style="width:16px;height:16px;margin-left:10px;margin-top:6px;cursor: pointer;" alt="">
            </div>

          </div>
        </div>

      </div>


    </div>
<!--  </el-affix>-->

  
</template>

<script >
// import { useRouter } from 'vue-router';
import bus from "@/eventBus/jdEventBus";
import { http } from "@/http/http";

export default {
	props:{
		selectedTab: {
			type: String,
			required: true,
		},
	},
	emits: ['update:selectedTab'], // 暴露`update:selectedTab`事件给父组件
	data() {
		return {
			localSelectedTab: this.selectedTab, // 将props的值复制到本地data属性
			isHome: false,
			keyWord: ""
		};
	},
	watch: {
		$route: {
			handler: function(v) {
				this.isHome = v.name === 'index' || v.name === "search"? true:false;
			}
		}
	},
  mounted() {
    bus.on("switchTabIndex",(info)=>{
      console.log("切换菜单：",info);
      this.localSelectedTab = info;
    });
  },
  methods:{
    handleEnterKeyPress() {
      if(this.keyWord === ""){
        return;
      }
      const query = this.keyWord;
      this.keyWord = "";
      this.$router.push({ path: '/search', query: { keyword: query } });
    },
    handleClick(newTab) {
      console.log('切换Tab:',newTab);
      // this.localSelectedTab = newTab.props.name; // 更新本地data属性
      // const router = useRouter();
      switch (newTab.props.name) {
        case 'index':
          this.$router.push({ name: 'index' }); // 假设首页路由名为index
        break;
        case 'library':
          this.$router.push({ name: 'library' }); // 假设文献典籍路由名为library
        break;
        case 'selected':
          this.$router.push({ name: 'selected' });
        break;
        case 'famous':
          this.$router.push({ name: 'famous' });
        break;
        case 'about':
          this.$router.push({ name: 'about' });
        break;
        case 'mine':
        {
          this.checkTokenToMine();
        }
        break;
        case 'download':
          this.$router.push({ name: 'download' });
        break;
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选，添加平滑滚动效果
      });
    },
    async checkTokenToMine() {
      if (this.$cookie.get("token")) {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let tokenResult = await http.checkToken(this.$cookie.get("token"));
        if (tokenResult.access) {
          console.log("用户已经登录，去获取数据！");

          let userResult = await http.get("/user/customer-user");
          console.log("用户信息：",userResult);
          if (userResult.code === 0) {
            ///存储用户是不是会员 0不是1是
            localStorage.setItem("isMember", userResult.data.data.isVip);
            this.$cookie.set("isMember", userResult.data.data.isVip);
            this.userInfo = userResult.data.data;
            if(this.userInfo.userAvatar == null || this.userInfo.userAvatar == ''){
              this.userInfo.userAvatar = "https://pic1.zhimg.com/80/v2-6afa72220d29f045c15217aa6b275808_720w.webp?source=1940ef5c";
            }
            console.log(this.userInfo);
          }

          this.$router.push({ name: 'bookself' });

        } else {
          console.log("用户登录失效，去登录！");
          this.$router.push({ name: 'login' });
        }
      } else {
        console.log("用户未登录，去登录！");
        this.$router.push({ name: 'login' });
      }
    },


  }
}

</script>



<style scoped>
/* #app {
  text-align: center;
  color: var(--ep-text-color-primary);
} */

.base-container {
  height: 64px;
  width: 100%;
  background-color: #EFE8E0;
  /**居中对其 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-container {
  /* *居中对其 */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: #EFE8E0;
  background-image: url('../../assets/index/bg_index_top.png');
  background-size: auto;
  background-position: top;
  /* background-color: red; */
}

.header-container {
  height: 64px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /**居中对其 */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}


/* 假设你的菜单容器有一个类名 `.menu-container` */
.menu-container {
	display: flex;
	justify-content: space-between; /* 确保菜单项均匀分布且最后一项不被折叠 */
	/* overflow-x: auto; 当菜单项过多时允许水平滚动 */
}

.custom-tabs{
  margin-top: 12px;
}

/* 如果需要修改激活标签的样式 */
.custom-tabs /deep/ .el-tabs__active-bar {
  background-color: transparent; /* 修改激活标签的下划线颜色 */
}

/* 如果需要修改标签项的样式 */
.custom-tabs /deep/ .el-tabs__item {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #CFA680;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  display: flex;
  flex-display: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
  margin-right: 48px;
}

/* 鼠标悬停时修改背景色 */
.custom-tabs /deep/ .el-tabs__item:hover {
  color: #FFE0AA; /* 修改激活状态标签的文字颜色 */
}

/* 如果需要修改激活状态的标签项样式 */
.custom-tabs /deep/ .el-tabs__item.is-active {
  font-weight: 600;
  color: #FFE0AA; /* 修改激活状态标签的文字颜色 */
  background-color: transparent; /* 修改激活状态标签的背景色 */
  border-bottom: 2px solid #FFE0AA;
}


.custom-tabs /deep/ .el-tabs__nav-wrap::after {
    background-color: transparent;
    border: none; /* 去除边框 */
    height: 0; /* 设置高度为 0，消除原有的高度影响 */
}
.sousuo {
	width:256px;
	height:28px;
	background: #80684D;
	border-radius: 16px;
	overflow: hidden;
	display: flex;
	margin-right: 22px;
}
/deep/ .el-input{
	background: #80684D !important;
	width: 210px;
	--el-input-border: #80684D;
	--el-input-hover-border: #80684D;
	--el-input-focus-border: #80684D;
}
/deep/ .el-input__wrapper{
	background: #80684D !important;
	border-color: #80684D;
	padding-right: 0px;
	margin-right: 10px;
	border-radius: 16px;
	padding-left: 0px;
	margin-left: 10px;
}
/deep/ .el-input__wrapper .el-input__inner{
	background: #80684D;
	font-size: 14px;
	height:28px;
	line-height: 28px;
	color: #FFE0AA;
	/* padding-left: 12px; */
	/* margin-left: -12px; */
}
/deep/input::-webkit-input-placeholder{
  -webkit-text-fill-color: #BEA488;
	line-height: 28px;
	font-size: 14px;
}




/*!*改变底部最显眼的白色粗犷滴边*!*/
/*.el-tabs__nav-wrap::after{*/
/*  background-color: transparent;*/
/*  border: none; !* 去除边框 *!*/
/*  height: 0; !* 设置高度为 0，消除原有的高度影响 *!*/
/*}*/

/*!*未选中时字体颜色*!*/
/*.el-tabs__item{*/
/*  color: #CFA680;*/
/*  margin-top: 10px;*/
/*}*/
/*.el-tabs__item:hover{*/
/*  color: #CFA680;*/
/*}*/
/*!*选中时底部边颜色*!*/
/*.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{*/
/*  border-bottom: 1px solid #FFE0AA;*/
/*}*/
/*!*选中时字体颜色*!*/
/*.el-tabs__item.is-active{*/
/*  color: #FFE0AA;*/
/*}*/
/*.el-tabs__active-bar{*/
/*  background-color: #FFE0AA;*/
/*}*/

/*!* 在你的全局或组件样式表中添加以下CSS以移除切换动画 *!*/
/*.no-animation .el-tabs__nav-wrap,*/
/*.no-animation .el-tabs__item {*/
/*  transition: none !important;*/
/*}*/

.more-button {
	/* 自定义样式以适应图片和文本 */
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	background-color: transparent;
	border-color: transparent;
	margin-right: 24px;

}

.more-img {
  width: 44px;
  height: 44px; /* 根据需要调整图片高度 */
}


</style>