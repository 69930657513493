<template>
	
	<div style="width: 100%;height: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;">
		
		
		<!-- 电脑端 -->
		<div class="dialog-login-view hidden-sm-and-down">
			
			<div class="dialog-login-header-view">
				
				<div class="dialog-login-header-view-title">
					<img src="../../assets/logo.png" style="width: 56px;height: 56px;margin-right: 12px;"/>
          下载APP后可收藏内容在线学习，还可以学习视频课程！
				</div>
				<div class="dialog-login-header-view-close" @click="closeDialog">
					<img src="../../assets/others/icon_close_dialog.png" style="width: 32px;height: 32px;"/>
				</div>
				
			</div>
				
			<div class="dialog-login-content-view">
				
				<div class="dialog-login-content-left-view">
					
					<div style="width: 240px;height: 308px;background-color: white;border-radius: 8px;display: flex;flex-direction: column;align-items: center;">
						<img :src="qrImgUrl" style="margin-top: 18px;width: 204px;height: 204px;"/>
						<!-- <div style="margin-top: 18px;" ref="qrcodeWebContainer"></div> -->
						<label style="font-family: PingFangSC, PingFang SC;font-weight: 500;font-size: 22px;color: #333333;line-height: 22px;text-align: center;font-style: normal;margin-top: 18px;">九典APP</label>
						<label style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 18px;color: #999999;line-height: 18px;text-align: center;font-style: normal;margin-top: 8px;">扫描二维码下载</label>
					</div>
					
				</div>
			</div>
			
		</div>
		
		<!-- 手机端 -->
		<div class="phone-dialog-login-view hidden-md-and-up">
			
			<div class="phone-dialog-login-header-view">
				
				<div class="phone-dialog-login-header-view-title">
					<img src="../../assets/logo.png" style="width: calc(80 * var(--screen-width-ratio));height: calc(80 * var(--screen-width-ratio));margin-right: calc(12 * var(--screen-width-ratio));"/>
          下载APP后可收藏内容在线学习，还可以学习视频课程！
				</div>
				<div class="phone-dialog-login-header-view-close" @click="closeDialog">
					<img src="../../assets/others/icon_close_dialog.png" style="width: calc(48 * var(--screen-width-ratio));height: calc(48 * var(--screen-width-ratio));"/>
				</div>
				
			</div>
				
			<div class="phone-dialog-login-content-view">
				
				<div class="phone-dialog-login-content-left-view">
					
					<div style="width: calc(322 * var(--screen-width-ratio));height: calc(414 * var(--screen-width-ratio));background-color: white;border-radius: calc(8 * var(--screen-width-ratio));display: flex;flex-direction: column;align-items: center;">
						<div style="margin-top: calc(18 * var(--screen-width-ratio)); width: calc(274 * var(--screen-width-ratio));height: calc(274 * var(--screen-width-ratio));">
							<el-image :src="require('../../assets/others/scan_code.png')" fit="cover" style="width: calc(274 * var(--screen-width-ratio));height: calc(274 * var(--screen-width-ratio));"></el-image>
						</div>
						<label style="font-family: PingFangSC, PingFang SC;font-weight: 500;font-size: calc(30 * var(--screen-width-ratio));color: #333333;line-height: calc(30 * var(--screen-width-ratio));text-align: center;font-style: normal;margin-top: calc(24 * var(--screen-width-ratio));">九典APP</label>
						<label style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: calc(24 * var(--screen-width-ratio));color: #999999;line-height: calc(24 * var(--screen-width-ratio));text-align: center;font-style: normal;margin-top: calc(10 * var(--screen-width-ratio));">扫描二维码下载</label>
					</div>
					
				</div>
				
			</div>
			
		</div>
		
		
	</div>

</template>

<script>

import { http } from "@/http/http";

export default {
	data() {
		return {
			qrImgUrl: 'https://c-ssl.dtstatic.com/uploads/item/201510/22/20151022160243_rUX4n.thumb.1000_0.gif/', // 这是你想要生成二维码的网址或信息
		};
	},
	mounted() {
		this.requestProtocol();
	},
	methods: {
		closeDialog() {
			console.log("关闭弹框");
			this.$emit("close-login-dialog");
		},
		// 查询二维码
		async requestProtocol(){
			let res = await http.get("/system/protocol", {
				type : 13
			});
			console.log(res.data);
			if(res.data.status === 0){
				// console.log(res.data.data.content);
				this.qrImgUrl = res.data.data.content;
			} else {
				console.log(res.data.describe);
			}
		},
	},
};
</script>
<style scoped>
	
	.dialog-login-view{
		width: 476px;
		height: 542px;
		background-image: url('../../assets/index/bg_index_content.png');
		background-size: auto;
		background-position: center;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	
	.dialog-login-header-view{
		height: 98px;
		padding-left: 18px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.dialog-login-header-view-title{
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 20px;
		color: #7B5025;
		line-height: 28px;
		text-align: justify;
		font-style: normal;
		flex: 1;
	}
	
	.dialog-login-header-view-close{
		width: 68px;
		height: 68px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-left: 6px;
	}
	
	.dialog-login-content-view{
		margin-left: 18px;
		margin-right: 18px;
		margin-bottom: 18px;
		background-color: white;
		flex: 1;
		border-radius: 8px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.dialog-login-content-left-view{
		width: 440px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-image: url('../../assets/others/icon_dialog_scan_bg.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
	}
	
	
	
	.phone-dialog-login-view{
		width: calc(640 * var(--screen-width-ratio));
		height: calc(729 * var(--screen-width-ratio));
		background-image: url('../../assets/index/bg_index_content.png');
		background-size: auto;
		background-position: center;
		border-radius: calc(8 * var(--screen-width-ratio));
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	
	.phone-dialog-login-header-view{
		height: calc(131 * var(--screen-width-ratio));
		padding-left: calc(24 * var(--screen-width-ratio));
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	
	.phone-dialog-login-header-view-title{
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: calc(28 * var(--screen-width-ratio));
		color: #7B5025;
		line-height: calc(40 * var(--screen-width-ratio));
		text-align: justify;
		font-style: normal;
		flex: 1;
	}
	
	.phone-dialog-login-header-view-close{
		width: calc(48 * var(--screen-width-ratio));
		height: calc(48 * var(--screen-width-ratio));
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-left: calc(24 * var(--screen-width-ratio));
		margin-right: calc(24 * var(--screen-width-ratio));
	}
	
	.phone-dialog-login-content-view{
		margin-left: calc(24 * var(--screen-width-ratio));
		margin-right: calc(24 * var(--screen-width-ratio));
		margin-bottom: calc(24 * var(--screen-width-ratio));
		background-color: white;
		flex: 1;
		border-radius: calc(8 * var(--screen-width-ratio));
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.phone-dialog-login-content-left-view{
		width: calc(592 * var(--screen-width-ratio));
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-image: url('../../assets/others/icon_dialog_scan_bg.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
	}
	
	

</style>