import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/theme-chalk/index.css';
// 引入全局CSS文件
import '@/styles/global.css'
import router from './route/index.js'
import jsCookie from 'js-cookie'
import { message } from './plugins/message'

// // 在 main.js 或其他初始化文件中
// const store = {
//   state: {
//     isLoginModalVisible: false,
//   },
//   // 显示需要登录框
//   showLoginModal() {
//     this.state.isLoginModalVisible = true;
//   },
//   hideLoginModal() {
//     this.state.isLoginModalVisible = false;
//   },
// };

const app = createApp(App)
installElementPlus(app)
app.config.globalProperties.$cookie = jsCookie;
app.config.globalProperties.$messageSingle = message;
app.use(router)
app.mount('#app')