import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/pages/Index"),
  },
  {
    path: "/library",
    name: "library",
    component: () => import("@/pages/library/Index"),
  },
  {
    path: "/column",
    name: "column",
    component: () => import("@/pages/column/Index"),
  },
  {
    path: "/selected",
    name: "selected",
    component: () => import("@/pages/selected/Index"),
  },
  {
    path: "/famous",
    name: "famous",
    component: () => import("@/pages/famous/Index"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/pages/about/Index"),
  },

  {
    path: "/bookself",
    name: "bookself",
    component: () => import("@/pages/mine/bookself/Index"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () => import("@/pages/mine/forgetPassword/Index"),
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () => import("@/pages/mine/resetPassword/Index"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/mine/login/Index"),
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/pages/mine/account/Index"),
  },
  {
    path: "/download",
    name: "download",
    component: () => import("@/pages/download/Index"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/pages/search/Index"),
  },
  {
    path: "/article",
    name: "article",
    component: () => import("@/pages/article/ArticleDetailsPage"),
  },
];
export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

export default router;
