<template>
  <div class="main-footer">
    <div class="footer-content-view">
      <div style="max-width: 1200px">
        <!-- web底栏容器 -->
        <div style="width: 100%; padding-bottom: 54px">
          <el-row :gutter="0">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div class="content">
                <div class="content-title">版权信息</div>
                <div class="content-info" style="margin-top: 24px">
                  版权声明：copyright©2005-2024 江苏攸同文化有限公司版权所有
                  本站内容未经授权禁止复制或者建立镜像
                </div>
                <div class="content-info" style="margin-top: 9px">
                  备案号:苏ICP备2022028299号-4
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div class="content">
                <div class="content-title">联系我们</div>
                <div class="content-info" style="margin-top: 24px">
                  地址：江苏省南京市鸣羊街27号
                </div>
                <div class="content-info" style="margin-top: 9px">
                  邮箱：dianzedushu@126.com
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div class="content hidden-sm-and-down">
                <div class="content-title">了解更多</div>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    margin-top: 24px;
                    align-items: center;
                    align-content: center;
                  "
                >
                  <div
                    style="
                      border-radius: 4px;
                      background-color: white;
                      align-items: center;
                      width: 76px;
                      height: 76px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img :src="qrImgUrl" style="width: 69px; height: 69px" />
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      margin-left: 25px;
                      justify-content: center;
                    "
                  >
                    <div class="content-info">九典官方APP</div>
                    <div class="content-info" style="margin-top: 10px">
                      扫描二维码下载
                    </div>
                    <div class="content-info">阅读更多精彩内容</div>
                  </div>
                </div>
              </div>
              <div class="content hidden-md-and-up">
                <div
                  style="
                    width: 100%;
                    height: 90px;
                    display: flex;
                    flex-direction: row;
                    margin-top: 24px;
                    align-items: center;
                    align-content: center;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-end;
                      height: 90px;
                    "
                  >
                    <div class="content-title">了解更多</div>
                    <div class="content-info" style="margin-top: 12px">
                      九典官方APP
                    </div>
                    <div class="content-info">
                      扫描二维码下载 阅读更多精彩内容
                    </div>
                  </div>
                  <div
                    style="
                      border-radius: 4px;
                      background-color: white;
                      align-content: center;
                      width: 90px;
                      height: 90px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img :src="qrImgUrl" style="width: 81px; height: 81px" />
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/http/http";

export default {
  data() {
    return {
      qrImgUrl: "@/assets/app.png", // 这是你想要生成二维码的网址或信息
    };
  },
  mounted() {
    this.requestProtocol();
  },
  methods: {
    // 查询二维码
    async requestProtocol() {
      let res = await http.get("/system/protocol", {
        type: 13,
      });
      console.log(res.data);
      if (res.data.status === 0) {
        // console.log(res.data.data.content);
        this.qrImgUrl = res.data.data.content;
      } else {
        console.log(res.data.describe);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-footer {
  width: 100%;
  background-color: white;
  /**居中对其 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
}

.footer-content-view {
  width: 100%;
  background-color: #4d3d2e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  align-items: flex-start;
  text-align: start;
  margin-left: 24px;
  margin-right: 24px;
}

.content-title {
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  margin-top: 30px;
}

.content-info {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 22px;
}
</style>
