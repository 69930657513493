<template>
  <div class="app-main-view">
    <BaseHeader
      :selectedTab="selectedTab"
      class="hidden-sm-and-down"
    ></BaseHeader>
    <router-view class="app-router-view"></router-view>
    <BaseFooter></BaseFooter>

    <el-dialog
      v-model="outerVisible"
      width="calc(750 * var(--screen-width-ratio))"
      align-center
      :show-close="false"
      style="
        padding: 0px 0px;
        background-color: #ffffff00;
        border-radius: calc(8 * var(--screen-width-ratio));
      "
    >
      <GlobalLogin
        @close-login-dialog="handleCloseLoginDialog"
        @login-success="handleLoginSuccess"
      ></GlobalLogin>
    </el-dialog>

    <el-dialog
      v-model="buyTipsVisible"
      width="calc(750 * var(--screen-width-ratio))"
      align-center
      :show-close="false"
      style="
        padding: 0px 0px;
        background-color: #ffffff00;
        border-radius: calc(8 * var(--screen-width-ratio));
      "
    >
      <BuyTipsAppView
        @close-login-dialog="handleCloseLoginDialog"
      ></BuyTipsAppView>
    </el-dialog>

    <el-drawer
      v-model="drawer"
      :with-header="false"
      direction="rtl"
      size="78.53%"
    >
      <div class="app-menu-drawer-content">
        <div class="app-menu-drawer-content-top">
          <div class="app-menu-item-view" @click="handleClick('index')">
            <span>首页</span>
            <img
              src="./assets/others/icon_right_zhutise.png"
              class="app-menu-item-view-img"
            />
          </div>
          <div class="app-menu-item-view" @click="handleClick('library')">
            <span>文献典籍</span>
            <img
              src="./assets/others/icon_right_zhutise.png"
              class="app-menu-item-view-img"
            />
          </div>
          <div class="app-menu-item-view" @click="handleClick('selected')">
            <span>精选专栏</span>
            <img
              src="./assets/others/icon_right_zhutise.png"
              class="app-menu-item-view-img"
            />
          </div>
          <div class="app-menu-item-view" @click="handleClick('famous')">
            <span>九典名篇</span>
            <img
              src="./assets/others/icon_right_zhutise.png"
              class="app-menu-item-view-img"
            />
          </div>
          <div class="app-menu-item-view" @click="handleClick('about')">
            <span>关于我们</span>
            <img
              src="./assets/others/icon_right_zhutise.png"
              class="app-menu-item-view-img"
            />
          </div>
          <div class="app-menu-item-view" @click="handleClick('mine')">
            <span>我的书架</span>
            <img
              src="./assets/others/icon_right_zhutise.png"
              class="app-menu-item-view-img"
            />
          </div>
          <div class="app-menu-item-view" @click="handleClick('account')">
            <span>账户管理</span>
            <img
              src="./assets/others/icon_right_zhutise.png"
              class="app-menu-item-view-img"
            />
          </div>
          <div class="app-menu-item-view" @click="handleClick('download')">
            <span>下载APP</span>
            <img
              src="./assets/others/icon_right_zhutise.png"
              class="app-menu-item-view-img"
            />
          </div>
        </div>
        <div class="app-menu-drawer-content-bottom">
          <img src="./assets/index/logo.png" class="app-menu-logo-img" />
          - 美好生活人文社区 -
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import BaseHeader from "./components/layouts/BaseHeader.vue";
import BaseFooter from "./components/layouts/BaseFooter.vue";
import bus from "./eventBus/jdEventBus";
import GlobalLogin from "./components/dialogs/GlobalLogin.vue";
import BuyTipsAppView from "./components/dialogs/BuyTipsAppView.vue";
import { http } from "@/http/http";

export default {
  name: "App",
  onLoad() {
    this.selectedTab = this.$route.path;
    console.log("当前路径:", this.$route.path);
  },
  components: {
    BaseHeader,
    BaseFooter,
    GlobalLogin,
    BuyTipsAppView,
  },
  mounted() {
    bus.on("openMenu", (info) => {
      console.log("打开菜单：", info);
      this.drawer = info;
    });
    bus.on("openDialog", (openType) => {
      console.log("打开弹框：", openType);
      if (openType === 1) {
        // 登录提示
        this.outerVisible = true;
      } else if (openType === 2) {
        // 下载APP提示
        this.buyTipsVisible = true;
      } else {
        this.outerVisible = false;
        this.buyTipsVisible = false;
      }
    });
  },
  data() {
    return {
      selectedTab: "index",
      drawer: false,
      outerVisible: false,
      buyTipsVisible: false,
    };
  },
  methods: {
    handleClick(tabName) {
      console.log("切换Tab:", tabName);
      // this.localSelectedTab = newTab.props.name; // 更新本地data属性
      // const router = useRouter();
      switch (tabName) {
        case "index":
          this.$router.push({ name: "index" }); // 假设首页路由名为index
          break;
        case "library":
          this.$router.push({ name: "library" }); // 假设文献典籍路由名为library
          break;
        case "selected":
          this.$router.push({ name: "selected" });
          break;
        case "famous":
          this.$router.push({ name: "famous" });
          break;
        case "about":
          this.$router.push({ name: "about" });
          break;
        case "mine":
          this.checkTokenToMine();
          // this.$router.push({ name: 'mine' }); // 假设个人中心路由名为mine
          break;
        case "download":
          this.$router.push({ name: "download" });
          break;
        case "account":
          this.$router.push({ name: "account" });
          break;
      }
      this.drawer = false;

      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选，添加平滑滚动效果
      });
    },
    handleCloseLoginDialog() {
      console.log("关闭登录弹框");
      this.outerVisible = false;
      this.buyTipsVisible = false;
    },
    handleLoginSuccess() {
      this.outerVisible = false;
      this.$messageSingle.success("登录成功！");
    },

    async checkTokenToMine() {
      if (this.$cookie.get("token")) {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let tokenResult = await http.checkToken(this.$cookie.get("token"));
        if (tokenResult.access) {
          console.log("用户已经登录，去获取数据！");

          let userResult = await http.get("/user/customer-user");
          console.log("用户信息：", userResult);
          if (userResult.code === 0) {
            ///存储用户是不是会员 0不是1是
            localStorage.setItem("isMember", userResult.data.data.isVip);
            this.$cookie.set("isMember", userResult.data.data.isVip);
            this.userInfo = userResult.data.data;
            if (
              this.userInfo.userAvatar == null ||
              this.userInfo.userAvatar == ""
            ) {
              this.userInfo.userAvatar =
                "https://pic1.zhimg.com/80/v2-6afa72220d29f045c15217aa6b275808_720w.webp?source=1940ef5c";
            }
            console.log(this.userInfo);
          }

          this.$router.push({ name: "bookself" });
        } else {
          console.log("用户登录失效，去登录！");
          this.$router.push({ name: "login" });
        }
      } else {
        console.log("用户未登录，去登录！");
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #efe8e0;
}

body {
  margin: 0px;
}
/*calc(750 * var(--screen-width-ratio))*/
.app-main-view {
  width: 100%;
  height: 100%;
  display: flex;
  /*overflow: hidden;*/
  flex-direction: column;
  justify-content: flex-start; /* 或者使用 'flex-start'，'center'，'space-around' 等根据实际需求 */
  align-items: flex-start; /* 这将使得flex子项在交叉轴方向尽可能拉伸以填满容器 */
}

.app-main-view > * {
  /* 这里的 '*' 表示容器的所有直接子元素 */
  flex-grow: 1; /* 这样设置后，所有子元素会平均分配剩余空间，尽可能填满容器 */
  width: 100%; /* 根据实际情况决定是否需要设置子元素宽度为100% */
  background-color: rgba(0, 0, 0, 0.5);
}

.app-router-view {
  /* background-image: url('./assets/index/bg_index_content.png'); */
  /*background-size: contain;*/
  background-size: 100%;
  background-color: #efe8e0;
  background-position: top;
  background-repeat: no-repeat;
}

.el-drawer {
  width: calc(589 * var(--screen-width-ratio));
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #efe8e0;
  background-image: url("./assets/others/menu_bg.png");
  background-size: 100% 100%; /* 或其他合适的背景尺寸属性 */
  background-position: top; /* 或其他合适的背景位置属性 */
  padding: 0;
  /* box-sizing: border-box; */
}
.el-drawer__body {
  width: calc(529 * var(--screen-width-ratio));
}
.app-menu-drawer-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.app-menu-drawer-content-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.app-menu-drawer-content-bottom {
  width: 100%;
  height: calc(110 * var(--screen-width-ratio));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: calc(24 * var(--screen-width-ratio));
  color: #c7b69f;
  line-height: calc(24 * var(--screen-width-ratio));
  text-align: center;
  font-style: normal;
}

.app-menu-logo-img {
  width: calc(136 * var(--screen-width-ratio));
  height: calc(59 * var(--screen-width-ratio));
}

.app-menu-item-view {
  width: 100%;
  height: calc(92 * var(--screen-width-ratio));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: calc(32 * var(--screen-width-ratio));
  color: #804a00;
  line-height: calc(32 * var(--screen-width-ratio));
  text-align: left;
  font-style: normal;
  border-bottom: calc(1 * var(--screen-width-ratio)) solid #e0d5c5;
}
.app-menu-item-view-img {
  width: calc(36 * var(--screen-width-ratio));
  height: calc(36 * var(--screen-width-ratio));
}
</style>
