import { service } from "./service";

export const http = {
  async get(url, params) {
    return service.get(url, { params }).then(
      (res) => {
        if (res.status == "0") {
          return { data: res, code: 0 };
        } else {
          return { data: res, code: -1 };
        }
      },
      (err) => {
        return err.data;
      }
    );
  },

  async post(url, params) {
    return service.post(url, params).then(
      (res) => {
        if (res.status == "0") {
          return { data: res, code: 0 };
        } else {
          return { data: res, code: -1 };
        }
      },

      (err) => {
        return err.data;
      }
    );
  },

  async put(url, params) {
    return service.put(url, params).then(
      (res) => {
        if (res.status == "0") {
          return { data: res, code: 0 };
        } else {
          return { data: res, code: -1 };
        }
      },

      (err) => {
        return err.data;
      }
    );
  },

  async delete(url, params, headers = {}) {
    return service.delete(url, { params, headers }).then(
      (res) => {
        if (res.status == "0") {
          return { data: res, code: 0 };
        } else {
          return { data: res, code: -1 };
        }
      },
      (err) => {
        return err.data;
      }
    );
  },

  ///通过token查询用户是否是APP的用户
  async checkToken() {
    let result = await this.get("/user/customer-user");
	console.log("检验token是否失效：",result);
    if (result.code === 0) {
      if (result.data.status == 0) {
		// 保存新的用户信息
		localStorage.setItem("userInfo", JSON.stringify(result.data.data));
        ///token有效
		return {
			access: true,
			isVip: result.data.data.isVip == "0" ? false : true,
		};
      }
		localStorage.removeItem("userInfo");
      ///请求失败
      return {
        access: false,
        isVip: false,
      };
    } else {
		localStorage.removeItem("userInfo");
      ///http报错
      return {
        access: false,
        isVip: false,
      };
    }
  },

  ///商品下单,实体虚拟都是统一接口
  async getOrderNo(params) {
    let result = await this.put("/shop/shop-order", params);
    console.log("下单的参数", params);
    return result;
  },

  ///bindUserId 邀请人id
  ///productId 虚拟和实体商品ID
  ///nickname 邀请人
  async jieliu(bindUserId, productId, nickname) {
    let params = {
      bindUserId: bindUserId,
      productId: productId,
      productName: nickname,
    };

    let res = await this.put("/user/agent-custom", params);
    if (res.code == 0) {
      console.log("截流绑定成功");
    } else {
      console.log("截流绑定失败");
    }
  },

  ///获取用户地址
  async getAddress() {
    let result = await this.get("/shop/shop-addr");
    console.log(result, "地址地址");
    if (result.code === 0) {
      let response = result.data.data;
      return response;
    } else {
      return [];
    }
  },
};
