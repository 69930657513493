<template>
	
	<div style="width: 100%;height: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;">
		
		<!-- 电脑端 -->
		<div class="dialog-login-view hidden-sm-and-down">
			
			<div class="dialog-login-header-view">
				
				<div class="dialog-login-header-view-title">
					<img src="../../assets/logo.png" style="width: 36px;height: 36px;margin-right: 12px;"/>
					登录后可收藏内容在线学习，还可以下载APP学习视频课程！
				</div>
				<div class="dialog-login-header-view-close" @mouseenter="()=>{this.isShow=false}" @mouseleave="()=>{this.isShow=true}"  @click="closeDialog">
					<img v-if="isShow" src="../../assets/others/icon_close_dialog.png" style="width: 32px;height: 32px;"/>
					<img v-else src="../../assets/others/icon_close_mouse_enter.png" style="width: 32px;height: 32px;"/>
				</div>
				
			</div>
				
			<div class="dialog-login-content-view">
				
				<div class="dialog-login-content-left-view">
					
					<div style="width: 240px;height: 308px;background-color: white;border-radius: 8px;display: flex;flex-direction: column;align-items: center;">
						<img :src="qrImgUrl" style="margin-top: 18px;width: 204px;height: 204px;"/>
						<label style="font-family: PingFangSC, PingFang SC;font-weight: 500;font-size: 22px;color: #333333;line-height: 22px;text-align: center;font-style: normal;margin-top: 18px;">九典APP</label>
						<label style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 18px;color: #999999;line-height: 18px;text-align: center;font-style: normal;margin-top: 8px;">扫描二维码下载</label>
					</div>
					
				</div>
				
				<div class="dialog-login-content-right-view">
					
					<label class="dialog-login-title-text">{{loginType == 0 ? '验证码登录' : '密码登录'}}</label>
					<label class="dialog-login-info-text" style="margin-top: 12px;margin-bottom: 24px;">{{loginType == 0 ? '- 未注册的手机号登录成功后将自动注册 -' : '- 请输入手机号和密码进行登录 -'}}</label>
					
					<div class="dialog-login-input-container" style="margin-bottom: 16px;">
						<input type="phone" class="dialog-login-input" placeholder="请输入手机号" v-model="phoneText" />
					</div>
					<div class="dialog-login-input-container" v-if="loginType == 0">
						<input type="text" class="dialog-login-input" placeholder="请输入验证码" v-model="codeText" />
						<div style="width: 1px;height: 20px;background-color:#E9E9E9;"></div>
						<button class="dialog-login-get-code-button" :style="mobileText==='获取验证码'?'color: #ea5717':''" @click="getCodeFn">{{mobileText}}</button>
					</div>
					<div class="dialog-login-input-container" v-else-if="loginType == 1">
						<input :type="passwordType" class="dialog-login-input" placeholder="请输入密码" v-model="passwordText" />
						<div style="width: 1px;height: 20px;background-color:#E9E9E9;"></div>
						<div class="dialog-logic-hidden-password-view" @click="togglePasswordVisibility">
							<img :src="passwordType === 'password' ? require('../../assets/others/icon_password_hidden.png') : require('../../assets/others/icon_password_see.png')" style="width: 24px;height: 24px;"/>
						</div>
					</div>
					
					<div style="width: 466px;height: 50px;display: flex;flex-direction: row;justify-content: space-between;">
						<button class="dialog-switch-login-type-button" @click="switchLoginType()">{{loginType == 0 ? '密码登录' : '验证码登录'}}</button>
						<button class="dialog-switch-login-type-button" style="color:#999999" v-if="loginType == 1" @click="forgetPassword">忘记密码</button>
					</div>
					
					<div class="dialog-login-button" :style="'background-color:' + ((isCheck && phoneText.length>0 && ((loginType == 0 && codeText.length>0) || (loginType == 1 && passwordText.length>0))) ? '#ea5717' : '#E6E6E6') + ';'" @click="onSubmit">登录</div>
					
					<div class="dialog-xieyi-view">
						<div style="width: 24px;height: 48px;display: flex;align-items: center;" @click="switchCheck">
							<img :src="isCheck ? require('../../assets/others/icon_checked.png') : require('../../assets/others/icon_uncheck.png')" style="width: 16px;height: 16px;"/>
						</div>
						<p style="color:#999999">
							我已阅读并同意
							<a href="http://h5.jsytwh.com/agreement/user" target="_blank" class="noUnderline1" style="color: #EA5717;">《九典用户协议》</a>、
							<a href="http://h5.jsytwh.com/agreement/init" target="_blank" class="noUnderline1" style="color: #EA5717;">《隐私政策》</a>
						</p>
					</div>
					
				</div>
				
			</div>
		
		</div>
	
		<!-- 手机端 -->
		<div class="phone-dialog-login-view hidden-md-and-up">
			<div class="phone-dialog-login-view-title">
				<div class="phone-dialog-login-view-title-text">{{loginType == 0 ? '验证码登录' : '密码登录'}}</div>
				<img class="phone-dialog-login-view-title-close" src="../../assets/others/icon_close_dialog.png" @click="closeDialog"/>
			</div>
			<label class="dialog-login-info-text" style="margin-bottom: calc(30 * var(--screen-width-ratio));font-size: calc(24 * var(--screen-width-ratio));line-height: calc(24 * var(--screen-width-ratio));">{{loginType == 0 ? '- 未注册的手机号登录成功后将自动注册 -' : '- 请输入手机号和密码进行登录 -'}}</label>
			<div class="phone-dialog-login-input-container" style="margin-bottom: calc(24 * var(--screen-width-ratio));width: calc(580 * var(--screen-width-ratio));height: calc(76 * var(--screen-width-ratio));">
				<input type="phone" class="phone-dialog-login-input" placeholder="请输入手机号" v-model="phoneText" />
			</div>
			<div class="phone-dialog-login-input-container" v-if="loginType == 0" style="margin-bottom: calc(24 * var(--screen-width-ratio));width: calc(580 * var(--screen-width-ratio));height: calc(76 * var(--screen-width-ratio));">
				<input type="text" class="phone-dialog-login-input" placeholder="请输入验证码" v-model="codeText" style="width: calc(290 * var(--screen-width-ratio));"/>
        <div style="display: flex;flex-direction: row;justify-content: center;width: calc(290 * var(--screen-width-ratio));align-items: center;">
          <div style="width: calc(1 * var(--screen-width-ratio));height: calc(28 * var(--screen-width-ratio));background-color:#E9E9E9;"></div>
          <button class="phone-dialog-login-get-code-button" :style="mobileText==='获取验证码'?'color: #ea5717':''" @click="getCodeFn">{{mobileText}}</button>
        </div>
			</div>
			<div class="phone-dialog-login-input-container" v-else-if="loginType == 1" style="margin-bottom: calc(24 * var(--screen-width-ratio));width: calc(580 * var(--screen-width-ratio));height: calc(76 * var(--screen-width-ratio));">
				<input :type="passwordType" class="phone-dialog-login-input" placeholder="请输入密码" v-model="passwordText" />
        <div style="width: calc(1 * var(--screen-width-ratio));height: calc(28 * var(--screen-width-ratio));background-color:#E9E9E9;"></div>
        <div class="dialog-logic-hidden-password-view" @click="togglePasswordVisibility">
          <img :src="passwordType === 'password' ? require('../../assets/others/icon_password_hidden.png') : require('../../assets/others/icon_password_see.png')" style="width: calc(32 * var(--screen-width-ratio));height: calc(32 * var(--screen-width-ratio));"/>
        </div>
			</div>
			
			<div class="phone-dialog-login-button" :style="'background-color:' + ((isCheck && phoneText.length>0 && ((loginType == 0 && codeText.length>0) || (loginType == 1 && passwordText.length>0))) ? '#ea5717' : '#E6E6E6') + ';'" @click="onSubmit">登录</div>
			
			<div class="phone-dialog-xieyi-view">
				<div style="width: calc(32 * var(--screen-width-ratio));height: calc(60 * var(--screen-width-ratio));display: flex;align-items: center;" @click="switchCheck">
					<img :src="isCheck ? require('../../assets/others/icon_checked.png') : require('../../assets/others/icon_uncheck.png')" style="width: calc(24 * var(--screen-width-ratio));height: calc(24 * var(--screen-width-ratio));"/>
				</div>
				<p>
					我已阅读并同意
					<a href="http://h5.jsytwh.com/agreement/user" target="_blank" class="noUnderline2" style="color: #AAAAAA;">《九典用户协议》</a>、
					<a href="http://h5.jsytwh.com/agreement/init" target="_blank" class="noUnderline2" style="color: #AAAAAA;">《隐私政策》</a>
				</p>
			</div>
			
			<div style="width: calc(580 * var(--screen-width-ratio));height: calc(106 * var(--screen-width-ratio));display: flex;flex-direction: row;justify-content: center;align-items: center;border-top: calc(1 * var(--screen-width-ratio)) solid #E9E9E9;margin-left: calc(6 * var(--screen-width-ratio));">
				<button class="dialog-switch-login-type-button" @click="switchLoginType()">{{loginType == 0 ? '密码登录' : '验证码登录'}}</button>
				<!-- <button class="dialog-switch-login-type-button" v-if="loginType == 1" @click="forgetPassword">忘记密码</button> -->
			</div>
			
		</div>
	
	
	</div>

</template>

<script>

// import QRCode from 'qrcodejs2-fix'
import { http } from "@/http/http";

export default {
	data() {
		return {
			qrImgUrl: 'https://c-ssl.dtstatic.com/uploads/item/201510/22/20151022160243_rUX4n.thumb.1000_0.gif/', // 这是你想要生成二维码的网址或信息
			loginType:0,//0 验证码登录   1 账号密码登录
			passwordType: 'password',
			phoneText:"",
			codeText:"",
			passwordText:"",
			isCheck:false,// 是否选中协议
			time: 60, /// UI显示 -1 发送验证码 0 重新发送 >0 倒计时
			mobileText : '获取验证码',
			isActive: true,
			isShow: true,
		};
	},
	mounted() {
		this.requestProtocol();
	},
	methods: {
		closeDialog() {
			console.log("关闭弹框");
			this.$emit("close-login-dialog");
		},
		// 查询二维码
		async requestProtocol(){
			let res = await http.get("/system/protocol", {
				type : 13
			});
			console.log(res.data);
			if(res.data.status === 0){
				// console.log(res.data.data.content);
				this.qrImgUrl = res.data.data.content;
			} else {
				console.log(res.data.describe);
			}
		},
		switchLoginType(){
			this.loginType = this.loginType == 0 ? 1 : 0;
		},
		forgetPassword(){
			console.log("忘记密码");
			this.$emit('close-login-dialog');
      this.$router.push({ path: '/forgetPassword'});
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选，添加平滑滚动效果
      });
		},
		togglePasswordVisibility() {
			if (this.passwordType === 'password') {
				this.passwordType = 'text';
			} else {
				this.passwordType = 'password';
			}
		},
		switchCheck(){
			this.isCheck = !this.isCheck;
		},
		getCodeFn() {
			const reg = /^1[3-9][0-9]{9}$/;
			if (!this.phoneText) {
				this.$messageSingle.warning("请输入手机号");
				return;
			}
			if (!reg.test(this.phoneText)) {
				this.$messageSingle.warning("请输入正确的手机格式");
				return;
			}
			if (this.time !== 60) return;
			
			this.time--;
			this.isActive = false;
			this.mobileText = `重新发送(${this.time}s)`;
			let t = setInterval(() => {
				if (this.time <= 1) {
					clearInterval(t);
					this.time = 60;
					this.isActive = true;
					this.mobileText = `获取验证码`;
					return;
				}
				this.time--;
				this.isActive = false;

				this.mobileText = `重新发送(${this.time}s)`;
			}, 1000);
			this.regCodeApi();
		},
		// 获取验证码接口
		async regCodeApi() {
			let res = await http.post("/user/info-sms/code", { mobile: this.phoneText });
			if (res.code === 0) {
				return res.data;
			} else {
				console.log(res.data);
				return;
			}
		},
		// 提交登录
		async onSubmit(){
			const reg = /^1[3-9][0-9]{9}$/;
			if (!this.phoneText) {
				this.$messageSingle.warning("请输入手机号");
				return;
			}
			if (!reg.test(this.phoneText)) {
				this.$messageSingle.warning("请输入正确的手机格式");
				return;
			}

      if (!this.isCheck) {
        this.$messageSingle.warning("请先同意用户协议");
        return;
      }

			// 设置token值，有效期为30天后
			const expirationDate = new Date();
			expirationDate.setDate(expirationDate.getDate() + 30); // 将日期加30天
			
			if(this.loginType == 0){
				// 验证码登录
				const regCode = /^\d{4}$/;
				if (!this.codeText) {
					this.$messageSingle.warning("请输入验证码");
					return;
				}
				if (!regCode.test(this.codeText)) {
					this.$messageSingle.warning("请输入正确的验证码");
					return;
				}
				
				let res = await http.post("/user/customer-user/loginMobileCode", {
					platform: '0',
					mobile: this.phoneText,
					verificationCode:this.codeText,
					inviteCode:''
				});
				console.log("验证码登录结果:",res);
				if (res.code === 0) {
					this.$cookie.set("token", res.data.data.tokenValue, {expires: expirationDate});
					// this.redirectUrl(res.data.data);
					this.$emit('login-success',res.data.data);
				} else {
					this.$messageSingle.warning(res.data.describe);
				}
				
			}else {
				// 账号密码登录
				if (this.passwordText.length < 8) {
					this.$messageSingle.warning("请输入正确的密码");
					return;
				}
				
				let res = await http.post("/user/customer-user/loginByMobilePass", {
					platform: '0',
					mobile: this.phoneText,
					password:this.passwordText
				});
				console.log("密码登录结果:",res);
				if (res.code === 0) {
					this.$cookie.set("token", res.data.data.tokenValue, {expires: expirationDate});
					// this.redirectUrl(res.data.data);
					this.$emit('login-success',res.data.data);
				} else {
					this.$messageSingle.warning(res.data.describe);
				}
				
			}
		}
	},
};
</script>
<style scoped>
	
	.dialog-login-view{
		width: 960px;
		height: 516px;
		background-image: url('../../assets/index/bg_index_content.png');
		background-size: auto;
		background-position: center;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	
	.dialog-login-header-view{
		height: 72px;
		padding-left: 18px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.dialog-login-header-view-title{
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 20px;
		color: #7B5025;
		line-height: 20px;
		text-align: justify;
		font-style: normal;
	}
	
	.dialog-login-header-view-close{
		width: 68px;
		height: 68px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	
	.dialog-login-content-view{
		margin-left: 18px;
		margin-right: 18px;
		margin-bottom: 18px;
		background-color: white;
		flex: 1;
		border-radius: 8px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.dialog-login-content-left-view{
		width: 378px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-image: url('../../assets/others/icon_dialog_scan_bg.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
	}
	
	.dialog-login-content-right-view{
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	
	.dialog-login-title-text{
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 22px;
		color: #333333;
		line-height: 22px;
		text-align: left;
		font-style: normal;
	}
	
	.dialog-login-info-text{
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 18px;
		color: #999999;
		line-height: 18px;
		text-align: center;
		font-style: normal;
	}
	
	.dialog-login-phone-input{
		width: 466px;
		height: 52px;
		border-radius: 6px;
		border: 1px solid #DDDDDD;
		/* padding-left: 16px;
		padding-right: 16px; */
	}
	
	
	.dialog-login-input-container{
		width: 466px;
		height: 52px;
		margin-left: 24px;
		margin-right: 24px;
		background-color: #FFFFFF;
		border-radius: 6px;
		border: 1px solid #DDDDDD;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	
	.dialog-login-input {
		flex: 1;
		/* 使用Element UI/Plus时，可能需要更具体的类名来覆盖背景颜色 */
		background-color: transparent !important;
		border: none; /* 如果需要移除边框 */
		padding: 16px 16px; /* 内边距以适应内容 */
		color: #000000; /* 占位文字颜色 */
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 20px;
		line-height: 20px;
		text-align: left;
		font-style: normal;
		font-size: 16px;
	}
	
	/* 当输入框获得焦点时修改文字颜色 */
	.dialog-login-input:focus {
		outline: none; /* 移除默认轮廓线 */
		color: #000000; /* 输入文字颜色 */
	}

  /* 需要注意的是，placeholder的颜色需要特定的CSS伪类来设置 */
  .dialog-login-input::placeholder {
    color: #CCCCCC; /* 占位文字颜色 */
  }
	
	
	
	.phone-dialog-login-input-container{
		width: calc(580 * var(--screen-width-ratio));
		height: calc(76 * var(--screen-width-ratio));
		/*margin-left: calc(30 * var(--screen-width-ratio));*/
		/*margin-right: calc(30 * var(--screen-width-ratio));*/
		background-color: #FFFFFF;
		border-radius: calc(8 * var(--screen-width-ratio));
		border: calc(1 * var(--screen-width-ratio)) solid #DDDDDD;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	
	.phone-dialog-login-input {
		flex: 1;
		/* 使用Element UI/Plus时，可能需要更具体的类名来覆盖背景颜色 */
		background-color: transparent !important;
		border: none; /* 如果需要移除边框 */
		padding: calc(24 * var(--screen-width-ratio)) calc(24 * var(--screen-width-ratio)); /* 内边距以适应内容 */
		color: #000000; /* 占位文字颜色 */
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: calc(28 * var(--screen-width-ratio));
		line-height: calc(28 * var(--screen-width-ratio));
		text-align: left;
		font-style: normal;
    caret-color: #EA5717;
	}
	
	/* 当输入框获得焦点时修改文字颜色 */
	.phone-dialog-login-input:focus {
		outline: none; /* 移除默认轮廓线 */
		color: #000000; /* 输入文字颜色 */
	}
	
	/* 需要注意的是，placeholder的颜色需要特定的CSS伪类来设置 */
	.phone-dialog-login-input::placeholder {
		color: #CCCCCC; /* 占位文字颜色 */
	}
	
	.dialog-switch-login-type-button{
		height: 50px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 18px;
		color: #666666;
		line-height: 50px;
		background-color: transparent;
		border: none;
		padding: 0px 0px;
		text-align: left;
		font-style: normal;
	}
	/*.dialog-switch-login-type-button:hover{*/
	/*	color: #EA5717 !important;*/
	/*	font-weight: 500;*/
	/*}*/
	
	.dialog-login-get-code-button{
		width: 166px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #999999;
		line-height: 52px;
		text-align: center;
		font-style: normal;
		background-color: transparent;
		border: none;
	}
	
	.phone-dialog-login-get-code-button{
		width: 100%;
    /*margin-left: calc(24 * var(--screen-width-ratio));*/
    /*margin-right: calc(24 * var(--screen-width-ratio));*/
    height: calc(76 * var(--screen-width-ratio));
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: calc(28 * var(--screen-width-ratio));
		color: #999999;
		line-height: calc(76 * var(--screen-width-ratio));
		text-align: center;
		font-style: normal;
		background-color: transparent;
		border: none;
	}
	
	.dialog-logic-hidden-password-view{
		width: 52px;
		height: 52px;
		border: none;
		background-color: transparent;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		align-content: center;
		justify-items: center;
	}
	
	.dialog-xieyi-view{
		display: flex;
		flex-direction: row;
		height: 48px;
		width: 466px;
		align-items: center;

	}

  /* 定义链接样式 */
  .noUnderline1 {
    text-decoration: none; /* 去掉下划线 */
    color: #AAAAAA; /* 设置文字颜色 */
  }
	
	.phone-dialog-xieyi-view{
		display:flex;
		flex-direction: row;
		width: calc(580 * var(--screen-width-ratio));
		height: calc(60 * var(--screen-width-ratio));
		align-items: center;
		margin-bottom: calc(12 * var(--screen-width-ratio));
		color: #AAAAAA;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: calc(24 * var(--screen-width-ratio));
    color: #AAAAAA;
    line-height: calc(24 * var(--screen-width-ratio));
    text-align: left;
    font-style: normal;
	}

  /* 定义链接样式 */
  .noUnderline2 {
    text-decoration: none; /* 去掉下划线 */
    color: #AAAAAA; /* 设置文字颜色 */
  }

	
	.dialog-login-button{
		background-color: #ea5717;
		border-radius: 6px;
		width: 466px;
		height: 52px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 52px;
		text-align: center;
		font-style: normal;
	}
	
	.phone-dialog-login-button{
		background-color: #ea5717;
		border-radius: calc(8 * var(--screen-width-ratio));
		width: calc(580 * var(--screen-width-ratio));
		height: calc(76 * var(--screen-width-ratio));
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: calc(30 * var(--screen-width-ratio));
		color: #FFFFFF;
		line-height: calc(76 * var(--screen-width-ratio));
		text-align: center;
		font-style: normal;
	}
	
	
	
	.phone-dialog-login-view{
		width: calc(640 * var(--screen-width-ratio));
		height: calc(607 * var(--screen-width-ratio));
		background-color: white;
		border-radius: calc(12 * var(--screen-width-ratio));
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	.phone-dialog-login-view-title{
		width: calc(640 * var(--screen-width-ratio));
		height: calc(98 * var(--screen-width-ratio));
		position: relative;
	}

  .phone-dialog-login-view-title-text{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: calc(32 * var(--screen-width-ratio));
    color: #333333;
    line-height: calc(68 * var(--screen-width-ratio));
    text-align: center;
    font-style: normal;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%);
  }

  .phone-dialog-login-view-title-close{
    width: calc(48 * var(--screen-width-ratio));
    height: calc(48 * var(--screen-width-ratio));
    position: absolute;
    right: calc(18 * var(--screen-width-ratio));
    top: calc(18 * var(--screen-width-ratio));
  }

</style>